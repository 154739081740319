<template>
    <div>
        <div class="title_block mb-2">
            <h5 class="mb-0 font-active">Terms & Conditions</h5>
        </div>
        <p>
            <span>WHITE GOLD LAB DIAMOND SOLITAIRE NECKLACE WITH PURCHASE OVER $3,000.        </span>
            <i>Limited Time Offer: Receive a White Gold Lab Diamond Solitaire Necklace with any purchase over $3,000 until stock lasts.</i> <br>
            Customers who make a purchase totalling over $3,000 or more during the offer period will receive a 14 KT White Gold Lab Diamond Solitaire Necklace (1/2 ct. tw., F-G color, VS clarity). This offer cannot be combined with any other offer or applied retroactively. Limited quantities are available and the offer extends while supplies last. The jewelry offered in this promo cannot be exchanged or returned for refund, and will not be replaced if misplaced, lost, or stolen. Additionally, the free item does not qualify for any services such as lifetime warranty or trade-in and upgrade. If an item(s) from a purchase over $3,000 is returned, and the remaining value of the purchase is less than $3,000, then the free product must be returned back to Friendly Diamonds in its original condition, or its retail value will be deducted from the refund. Friendly Diamonds reserves the right, in its sole discretion, to not provide any jewelry in the event of any fraud or deception, and to substitute jewelry based on availability. Purchaser is solely responsible for reporting and paying any applicable duties & taxes associated with the receipt of the jewelry. The offer terms are subject to change at any time without prior notice. Other restrictions may apply.
            <br><br>
            <span>WHITE GOLD LAB DIAMOND STUD EARRINGS WITH PURCHASE OVER $5,000.</span>
            <i>Limited Time Offer: Receive a pair of White Gold Stud Earrings with any purchase over $5,000 until stock lasts.</i> <br>
            Customers who make a purchase totalling over $5,000 or more during the offer period will receive a pair of 14 KT White Gold Lab Diamond Stud Earrings (1 ct. tw., F-G color, VS clarity). This offer cannot be combined with any other offer or applied retroactively. Limited quantities are available and the offer extends while supplies last. The jewelry offered in this promo cannot be exchanged or returned for refund, and will not be replaced if misplaced, lost, or stolen. Additionally, the free item does not qualify for any services such as lifetime warranty or trade-in and upgrade. If an item(s) from a purchase over $5,000 is returned, and the remaining value of the purchase is less than $5,000, then the free product must be returned back to Friendly Diamonds in its original condition, or its retail value will be deducted from the refund. Friendly Diamonds reserves the right, in its sole discretion, to not provide any jewelry in the event of any fraud or deception, and to substitute jewelry based on availability. Purchaser is solely responsible for reporting and paying any applicable duties & taxes associated with the receipt of the jewelry. The offer terms are subject to change at any time without prior notice. Other restrictions may apply.
        </p>
    </div>
</template>

<style scoped>
p {
    font-size: 14px;
    margin-bottom: 0;
    /* padding-top: 9px; */
    color: #6a6161;
}
 p span {
    font-weight: 600;
    color: #212529;
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
}
.title_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
h5{
    font-size: 24px;
    text-align: center;
    color: #212529;
}
@media(max-width: 767px){
    h5{
        font-size: 20px;
    }
}
</style>